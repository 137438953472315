import React from "react";
import PrivateRoute from "components/PrivateRoute";
// Containers
import Profile from "containers/Profile";
import UserLogged from "../../containers/userLogged";
const S = () => {
  return (
    <UserLogged>
      <Profile />
    </UserLogged>
  );
};

export default S;
