//Local
import { API_ROOT, A2VOTE_API, DOMAIN, KEY_CRYPT } from "utils/config";
import { getAuthorization, getApiRoot } from "utils/token";
import { handleResponse, handleCatch } from "utils/handleResponse";

export const createUser = (id, login) => {
  return (dispatch) => {
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
      body: JSON.stringify({
        id,
        login,
      }),
    };
    fetch(`${A2VOTE_API}/user`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        console.log("res", res);
      })
      .catch(handleCatch);
  };
};

export const updateUserAction = (formData, pastMail) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_USER_START" });
    const requestedOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
      body: JSON.stringify({
        ...formData,
        pastMail,
      }),
    };
    fetch(`${A2VOTE_API}/user`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const data = localStorage.getItem("userFront") 
        localStorage.setItem("userFront", JSON.stringify({...res }));
        dispatch({ type: "UPDATE_USER_SUCCESS", payload: res });
      })
      //Catch error every time
      //.catch(handleCatch, dispatch({ type: "UPDATE_USER_ERROR" }));
  };
}

