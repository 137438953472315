import React from "react"
import { navigate } from "gatsby"
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userFront } = useSelector((state) => state.user);
  if(typeof window == 'undefined') {
    return null;
  }
  // if exists, we use data stored in user redux
  // 1 - set admin value
  let isAdmin = false;
  if(!userFront?.login) {
    const userFront = localStorage?.getItem('userFront');
    try {
      isAdmin = JSON.parse(userFront)?.admin;
    }
    catch({}) {
      isAdmin = false
    }
  }
  else {
    isAdmin = userFront?.admin;
  }
  // 2 - if admin return component
  if (isAdmin === true) {
    return <Component {...rest} />
  }
  // else escape
  navigate("/");
  return null
}

export default PrivateRoute