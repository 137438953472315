export const displayName = (user) => {
  if(user) {
    return user.firstname || user.lastname ? (
      `${user.firstname || ''} ${user.lastname || ''}`
    ) : (
      user.username
    );
  }
  else {
    return "";
  }
  
};