import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { checkUserFrontLogin } from "../actions/login";
import messages from "./messages";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dots = styled.div`
  width: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px, 19px 0 0 5px,
    38px 0 0 5px, 57px 0 0 5px, 19px 19px 0 0px, 38px 19px 0 0px,
    57px 19px 0 0px;
  transform: translateX(-38px);
  animation: d6 2s infinite linear;

  @keyframes d6 {
    12.5% {
      box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 5px,
        19px 0 0 5px, 38px 0 0 0px, 57px 0 0 5px, 19px 19px 0 0px,
        38px 19px 0 0px, 57px 19px 0 0px;
    }
    25% {
      box-shadow: 19px -19px 0 5px, 38px -19px 0 0px, 57px -19px 0 5px,
        19px 0 0 0px, 38px 0 0 0px, 57px 0 0 0px, 19px 19px 0 0px,
        38px 19px 0 5px, 57px 19px 0 0px;
    }
    50% {
      box-shadow: 19px -19px 0 5px, 38px -19px 0 5px, 57px -19px 0 0px,
        19px 0 0 0px, 38px 0 0 0px, 57px 0 0 0px, 19px 19px 0 0px,
        38px 19px 0 0px, 57px 19px 0 5px;
    }
    62.5% {
      box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
        19px 0 0 5px, 38px 0 0 0px, 57px 0 0 0px, 19px 19px 0 0px,
        38px 19px 0 5px, 57px 19px 0 5px;
    }
    75% {
      box-shadow: 19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
        19px 0 0 0px, 38px 0 0 0px, 57px 0 0 5px, 19px 19px 0 0px,
        38px 19px 0 0px, 57px 19px 0 5px;
    }
    87.5% {
      box-shadow: 19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
        19px 0 0 0px, 38px 0 0 5px, 57px 0 0 0px, 19px 19px 0 5px,
        38px 19px 0 0px, 57px 19px 0 0px;
    }
  }
`;

const Loading = styled.span`
  font-size: 1.5em;
  margin-left: 4%;
`;

const UserLogged = ({ children }) => {
  const [isLogged, setIsLogged] = useState(false);

  const dispatch = useDispatch();
  const autoLogin = () => dispatch(checkUserFrontLogin());

  useEffect(() => {
    // If user logged,
    // set state at true
    // else logout and navigate to login
    const cookies = new Cookies(document.cookie);
    const token = cookies.get("tokenUserFront");
    if (token) {
      if (localStorage.getItem("userFront") && localStorage.getItem("token")) {
        // logged
        setIsLogged(true);
      } else {
        // autologin
        autoLogin();
        setTimeout(() => {
          setIsLogged(true);
        }, 3000);
      }
    } else {
      //need to login
      navigate("/l");
      setIsLogged(true);
    }
  }, []);

  return (
    <div>
      {isLogged ? (
        React.Children.only(children)
      ) : (
        <Container>
          <Dots />
          <Loading>
            <FormattedMessage {...messages.loading} />
            ...
          </Loading>
        </Container>
      )}
    </div>
  );
};

export default UserLogged;
