import { defineMessages } from "react-intl";
export default defineMessages({

	edit_profile: {
		id: "a2vote.edit_profile",
		defaultMessage: "Modify my profile",
	},
	firstname: {
		id: "a2vote.firstname",
		defaultMessage: "First name",
	},
	lastname: {
		id: "a2vote.lastname",
		defaultMessage: "Last name",
	},
	mail: {
		id: "a2vote.user_mail",
		defaultMessage: "Mail",
	},
	phone: {
		id: "a2vote.phone",
		defaultMessage: "Phone",
	},
	organization: {
		id: "a2vote.organization",
		defaultMessage: "Organization",
	},
	confirm: {
		id: "a2vote.save",
		defaultMessage: "Save",
	},
	cancel: {
		id: "a2vote.cancel",
		defaultMessage: "Cancel",
	},
	complete_profile: {
		id: "a2vote.complete_profile",
		defaultMessage: "Complete my profile",
	},
	complete_profil_reminder: {
		id: "a2vote.complete_profil_reminder",
		defaultMessage: "Don't forget to complete your profile",
	},
	profile_complete: {
		id: "a2vote.profile_complete",
		defaultMessage: "Your profile is complete",
	},
	profile_complete_reminder: {
		id: "a2vote.profile_complete_reminder",
		defaultMessage: "Your profile is up to date, you can profit from application",
	},
	error: {
		id: "a2vote.input.error",
		defaultMessage: "Field cannot be empty",
	},
});